import merge from "lodash/merge";
import baseTheme from "./theme";
import colors from "./colors";
import styles from "./components-styles";
import "../components/layout.css";

export default merge({}, baseTheme, {
  colors,
  fonts: {
    bodyEN: "Overpass, system-ui, sans-serif",
    bodyJA: "Noto Sans JP, system-ui, sans-serif",
    headingEN: `Akrobat, sans-serif`,
    headingJA: `Noto Sans JP, sans-serif`,
    monospace: `Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`,
  },
  styles,
});
