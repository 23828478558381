// Gatsby Apolla

import fetch from "isomorphic-fetch"
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    fetch,
    uri: `https://backend.midorinomajo.com/graphql`,
  }),
})


