

export default {
        text: "#383E42",
        primary: "#2BB57A",
        light: "#96E596",
        lighter: "#D4F7CF",
        dark: "#0E8E57",

        variant: "#DDF0DD",
        white: "#fff",
        black: "#000",
    
        alert: "red",
};