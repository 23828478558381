exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-product-category-js": () => import("./../../../src/templates/product/category.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---template-cache-tmp-contact-js": () => import("./../../../.template-cache/tmp-contact.js" /* webpackChunkName: "component---template-cache-tmp-contact-js" */),
  "component---template-cache-tmp-home-js": () => import("./../../../.template-cache/tmp-home.js" /* webpackChunkName: "component---template-cache-tmp-home-js" */),
  "component---template-cache-tmp-privacy-policy-js": () => import("./../../../.template-cache/tmp-privacy-policy.js" /* webpackChunkName: "component---template-cache-tmp-privacy-policy-js" */),
  "component---template-cache-tmp-retailer-japan-js": () => import("./../../../.template-cache/tmp-retailer-japan.js" /* webpackChunkName: "component---template-cache-tmp-retailer-japan-js" */),
  "component---template-cache-tmp-terms-js": () => import("./../../../.template-cache/tmp-terms.js" /* webpackChunkName: "component---template-cache-tmp-terms-js" */)
}

