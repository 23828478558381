export default {
  breakpoints: ["576px", "860px", "1024px", "1280", "1600px"],
  sizes: {
    max: "100%",
    small: `520px`,
    textbox: `860px`,
    main: "1024px",
    homepage: "1208px",
    xl: "1460px",
  },
  space: [0, 8, 12, 24, 32, 46, 92, 138,184],
  // fonts: {
  //   body: "Overpass",
  //   heading: "Akrobat",
  //   monospace: "Menlo, monospace",
  // },
  fontSizes: [12, 14, 18, 24, 32, 48, 68],
  fontWeights: {
    body: 400,
    heading: 800,
    bold: 900,
  },
  lineHeights: {
    body: 1.5,
    headingEN: 1.125,
    headingJA: 1.4,
  },

  styles: {
    root: {
      "body.lang-ja > *": { fontFamily: "bodyJA" },
      "body.lang-en > *": { fontFamily: "bodyEN" },
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      color: "primary",
      "body.lang-ja > *": {
        fontFamily: "bodyJA",
        lineHeight: "headingJA",
        letterSpacing: `0.1rem`,
      },
      "body.lang-en > *": { fontFamily: "bodyEN", lineHeight: "headingEN" },
      // lineHeight: "heading",
      fontWeight: "heading", 
      fontSize: [4, 5, 5],
    },
    h2: {
      color: "text",
      "body.lang-ja > *": {
        fontFamily: "bodyJA",
        lineHeight: "headingJA",
        letterSpacing: `0.1rem`,
      },
      "body.lang-en > *": { fontFamily: "bodyEN", lineHeight: "headingEN" },
      // lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [5, 6],
    },
    h3: {
      color: "text",
      "body.lang-ja > *": {
        fontFamily: "bodyJA",
        lineHeight: "headingJA",
        letterSpacing: `0.1rem`,
      },
      "body.lang-en > *": { fontFamily: "bodyEN", lineHeight: "headingEN" },
      // lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [3, 4],
    },
    h4: {
      color: "text",
      "body.lang-ja > *": {
        fontFamily: "bodyJA",
        lineHeight: "headingJA",
        letterSpacing: `0.1rem`,
      },
      "body.lang-en > *": { fontFamily: "bodyEN", lineHeight: "headingEN" },
      // lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [3],
    },
    h5: {
      color: "text",
      "body.lang-ja > *": { fontFamily: "bodyJA", lineHeight: "headingJA" },
      "body.lang-en > *": { fontFamily: "bodyEN", lineHeight: "headingEN" },
      // lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [2],
    },
    h6: {
      color: "text",
      "body.lang-ja > *": { fontFamily: "bodyJA", lineHeight: "headingJA" },
      "body.lang-en > *": { fontFamily: "bodyEN", lineHeight: "headingEN" },
      // lineHeight: "heading",
      fontWeight: "heading",
      fontSize: [2],
    },
    p: {
      color: "text",
      "body.lang-ja > *": { fontFamily: "bodyJA" },
      "body.lang-en > *": { fontFamily: "bodyEN" },
      fontWeight: "body",
      lineHeight: "body",
      fontSize: [2],
      wordBreak: "break-word",
    },
    a: {
      color: "primary",
      textDecoration: "none",
      position: "relative",
    },

    pre: {
      fontFamily: "monospace",
      //overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
  },

  forms: {
    label: {
      fontSize: 1,
      svg: { color: `primary` },

      //  textTransform: "uppercase",
      color: `primary`,
    },
    input: {
      borderColor: "primary",
      background: "white",
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    checkbox: {
      borderColor: "primary !important",
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    select: {
      borderColor: "primary",
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    textarea: {
      borderColor: "primary",
      background: "white",

      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    slider: {},
  },

  buttons: {
    primary: {
      appearance: "none",
      display: "block",
      textAlign: "center",
      fontSize: [2],
      "body.lang-ja > *": { fontFamily: "bodyJA", lineHeight: "headingJA" },
      "body.lang-en > *": { fontFamily: "bodyEN", lineHeight: "headingEN" },
      fontWeight: "bold",
      borderStyle: "solid",
      borderRadius: "50px",
      textTransform: "uppercase",
      transitionDuration: "150ms",
      transitionProperty:
        "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter",
      // transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
      my: 2,
      width: `fit-content`,
      px: 3,
      py: 2,
      height: `fit-content`,
      // boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      boxShadow: `0 3px 5px 0 rgba(0,0,0,0.08)`,
      // transition: `box-shadow 0.3s ease-in-out`,

      "&:hover": {
        bg: "text",
        boxShadow: `0 10px 12px 0 rgba(0,0,0,0.2)`,

        // boxShadow: "none",
      },
    },
    blank: {
      appearance: `none`,
      border: `0`,
      background: `transparent`,
      cursor: `pointer`,
      ".sr-only": { display: `none` },
      ":focus": { outline: `none` },
    },
    invert: {
      backgroundColor: "white",
      color: "primary",
      borderColor: "white",

      "&:hover": {
        color: "white",
        bg: "primary",
      },
    },
    secondary: {
      color: "text",
      bg: "secondary",
      borderColor: "primary",
      "&:hover": {
        color: "secondary",
        bg: "text",
      },
    },
  },

  cards: {
    primary: {
      padding: [3, 4],
      borderRadius: [8],
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
    },
    compact: {
      padding: 1,
      borderRadius: 4,
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
    },
    big: {
      padding: [4, 5, 5, 6],
      margin: [0, 5, 5, 0],

      borderRadius: [0, 0, 6],
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
    },
  },

  // Volle Breite ohne Abstäne

  sectionFull: {
    width: `100%`,
    mx: `auto`,
    px: [4, 6],
  },

  // Max. Breite mit Padding

  sectionMain: {
    // display: `flex`,
    px: [4, 6],
    mt: 6,
    mx: `auto`,
    width: `100%`,

    flex: `1 1 auto `,
    maxWidth: (theme) => `${theme.sizes.main}`,
  },

  // Breite mit Padding

  sectionMainBox: {
    // display: `flex`,
    // px: [3, 6],
    mt: 6,
    mx: `auto`,
    width: `100%`,
    // mx: [0, 4, 0],
    // flex: `1 1 auto `,
    maxWidth: (theme) => `${theme.sizes.main}`,
  },

  sectionMed: {
    // display:`flex`,
    px: [4, 6, 5, 6, 0],
    mt: 6,

    width: `100%`,
    mx: `auto`,
    flex: `1 1 auto `,
    maxWidth: (theme) => `${theme.sizes.homepage}`,
  },

  // Mehr Max. Breite mit Padding

  sectionMax: {
    // display:`flex`,
    px: [4, 6],
    mt: 6,
    width: `100%`,
    mx: `auto`,
    flex: `1 1 auto `,
    maxWidth: (theme) => `${theme.sizes.xl}`,
  },

  // ???

  section: {
    width: `100%`,
    mx: `auto`,
    // mt: 6,
    px: [0, 0, 0, 0],
    // px: [3, 3, 4],
  },
};
